import { createRoot } from 'react-dom/client';
import React from 'react';
import { useState, useEffect, useMemo, useRef } from 'react';
import {
  MicrophoneIcon,
  VideoCameraIcon,
  PhoneXMarkIcon } from '@heroicons/react/24/outline'

import { RTCEasyMode } from 'rtc-easymode'

const container = document.getElementById('app');
const root = createRoot(container);


const roomControls = [
  { icon: MicrophoneIcon, activeColor: 'data-[active]:bg-red-600 data-[active]:border-red-600', buttonColor: 'bg-slate-500 border-slate-500' },
  { icon: VideoCameraIcon, activeColor: 'data-[active]:bg-red-600 data-[active]:border-red-600', buttonColor: 'bg-slate-500 border-slate-500' },
  { icon: PhoneXMarkIcon, buttonColor: 'bg-red-600 border-red-600' }
]


function Landing() {
  let startCall;
  const stream = useRef(null)
  const [devices, setDevices] = useState()
  const [audioMuted, setAudioMuted] = useState(true)
  const [videoMuted, setVideoMuted] = useState(true)
  startCall = useMemo(() => new RTCEasyMode(navigator.mediaDevices), [])
  useEffect(() => {
    const getDevices = async () => {
      stream.current.srcObject = await startCall.getMedia()
      await startCall.setTransceivers(stream.current.srcObject)
      await startCall.init();
    }


    // Get the local video element in the HTML and set the source to show local stream
    getDevices();
    stream.current.play()
    //localStream
    //  .getTracks()
    //  .forEach((track) => myPeerConnection.addTrack(track, localStream));

  }, [])
  return (
    <div className="bg-white flex flex-col h-full">
      <div className="relative flex-grow overflow-hidden isolate">
        <div className="absolute inset-0 h-full w-full bg-black isolate flex flex-wrap justify-around gap-[--gap] p-[--gap]">
          <video ref={stream} className="absolute w-full h-full inset-0 object-contain -scale-x-100" id="local" muted={audioMuted} playsInline={true}> Video is not available</video>
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-center gap-2 p-2 text-sm md:gap-4 md:p-5 md:text-base">
      {roomControls.map((i, t) => {
        return <button key={t} className={`shadow border-4 rounded uppercase font-bold tracking-widest py-[.5em] px-[1em] text-white  ${i.activeColor} ${i.buttonColor} hover:bg-red-700 hover:border-red-700`}> <i.icon className="size-6 text-white font-bold"/></button>
      })}
      </div>
    </div>
  )
}

root.render(<Landing />);
